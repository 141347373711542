import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { Container, Row, Col } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import StockImage from "../components/stockImage"
import QuoteForm from "../components/quoteForm"
import CallIcon from "../components/callIcon"

import InsuranceImage from "../images/stock-image-16.jpg"
import InsuranceImageMobile from "../images/stock-image-16-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-16-tablet.jpg"
import StockImage1 from "../images/stock-image-17.jpg"
import StockImage2 from "../images/stock-image-18.jpg"

const AccidentInsurance = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("accident_insurance")} />

      <InsuranceDescription
        title={t("accident_insurance_title")}
        subtitle={t("accident_insurance_subtitle")}
        actions={
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("quote_for_free")}
            </a>
          </>
        }
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <div className="bg-white py-md-6">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3 mb-4">
            <Col xs="12" md={{ span: 6, order: 0 }}>
              <StockImage src={StockImage1} />
            </Col>
            <Col
              xs="12"
              md={{ span: 6, order: 1 }}
              className="d-flex flex-column justify-content-center equalize-padding-x px-md-4 px-lg-6 pb-4 pb-md-0"
            >
              <span className="fs-3 fw-light">
                <Trans i18nKey="ai_f0_title">
                  <b>Protege a tu familia</b><br /> de la carga financiera en caso de un diagnóstico grave o una lesión.
                </Trans>
              </span>
            </Col>
          </Row>

          <Row className="gx-0 gx-md-3">
            <Col xs="12" md={{ span: 6, order: 1 }}>
              <StockImage src={StockImage2} />
            </Col>
            <Col
              xs="12"
              md={{ span: 6, order: 0 }}
              className="d-flex flex-column justify-content-center equalize-padding-x px-md-4 px-lg-6 pb-4 pb-md-0"
            >
              <span className="fs-3 fw-light">
                <Trans i18nKey="ai_f1_title">
                  Tenga en sus manos las herramientas para hacer frente financieramente a un <b>diagnóstico positivo de cáncer</b>.
                </Trans>
              </span>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="py-5 py-md-6">
        <Container>
          <Row>
            <Col xs="12" md="8" className="mx-auto">
              <p className="fs-1 fw-light lh-1 mb-4 mb-md-5">
                <Trans i18nKey="accident_insurance_title_2">
                  Tu seguro de salud primario <b>solo te cubre una parte de los gastos</b> en caso de enfermedad o lesión
                </Trans>
              </p>

              <h3 className="render-tags fw-normal">{t("accident_insurance_subtitle_2")}</h3>

              <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
                <CallIcon /> {APREMIA_PHONE_FORMATTED}
              </a>
              <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
                {t("quote_for_free")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default AccidentInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
